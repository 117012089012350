.home .header {
  border-bottom: 1px solid;
  padding: 20px 20px 10px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.home .account {
  padding: 20px 20px 10px;
}
.home .accountBalance {
  border-top: 1px solid;
  text-align: center;
}
.home .accountBalance .accountBalanceItem {
  padding: 4px 0px 10px;
}
.home .homeItem {
  padding: 10px 20px 10px;
}
.selectKBox {
  color: #f3b71e;
  padding: 6px;
  margin: 10px;
}
.selectKBoxIn {
  color: #f3b71e;
  padding: 6px;
  border-bottom: 1px solid #f3b71e;
  margin: 10px;
}
.kSelect {
  color: #f3b71e;
}
