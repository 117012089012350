.poolBox {
  padding: 20px;
  border-radius: 10px;
}
.poolBox .poolBoxTitle {
  padding-bottom: 5px;
}
.poolBox .poolBoxTitle p {
  font-weight: 500;
  font-size: 16px;
}
.poolBox .poolBoxDetail {
  padding-bottom: 30px;
}
.poolBox .poolBoxDetail p {
  font-weight: 400;
  font-size: 16px;
}
.poolBox .poolBtn div p {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  border: 1px solid #f3b71e;
  border-radius: 12px;
  font-size: 16px;
  padding: 6px 8px !important;
  cursor: pointer;
}
.pairItem {
  border-radius: 12px;
  padding: 16px;
  margin-top: 20px;
}
.pairItem .pairItemBtn {
  margin-top: 20px;
}
.pairItem .pairItemBtn div p {
  text-align: center;
  width: 130px;
  margin: 0 auto;
  border: 1px solid #f3b71e;
  border-radius: 12px;
  font-size: 16px;
  padding: 6px 8px !important;
  cursor: pointer;
  color: #ffffff;
  background: #f3b71e;
}
