.header {
    width: 100%;
    top: 0px;
    z-index: 2;

    .headerContent {
        padding: 10px 16px;
        position: absolute;
        width: 100%;
        z-index: 3;

        .headerLogo {
            position: relative;
            float: left;

            img {
                height: 32px;
            }
        }

        .headerOperation {
            position: relative;
            float: right;
            display: flex;

            .chain {
                padding: 5px 10px;
                background-color: rgba(243, 132, 30, 0.05);
                color: #f3b71e;
                border-radius: 10px;
                margin-right: 20px;

            }

            .headBalance{
                font-size: 14px;
                font-weight: 400;
                cursor: pointer;
                line-height: 32px;
            }

            .headerAccount {
                border: 1px solid;
                border-radius: 10px;
                padding: 5px 10px;

                p {
                    font-size: 14px;
                    font-weight: 400;
                    cursor: pointer;
                    line-height: 20px;
                }
            }

        }

        @media screen and (min-width: 900px) {
            .headerNavLink {
                display: flex;
                position: absolute;
            }
        }
    }

    .nav {
        position: absolute;
        width: 100%;
        margin-top: 5px;
        .navContent {
            width: 220px;
            margin: 0 auto;
            z-index: 4;
        }
    }

    @media screen and (max-width: 900px) {
        .nav {
            display: none;
        }
    }
}
@primary-color: #1DA57A;