.swapBox {
  padding: 20px;
  border-radius: 10px;
}
.currenHeader {
  padding: 16px 16px 12px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-right: 1px solid;
  border-left: 1px solid;
  border-top: 1px solid;
}
.currenHeader .selectPop {
  border-radius: 16px;
  padding: 0px 16px;
  margin-top: 2px;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 6px 10px;
  line-height: 38px;
}
.currenHeader .inputBox {
  margin-top: 2px;
}
.currenHeader .inputBox input {
  width: 100%;
  overflow: scroll;
  height: 38px;
  line-height: 38px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 24px;
  text-align: right;
}
.exchangeIcon {
  margin: 0 auto;
  position: relative;
  width: 32px;
  height: 32px;
  margin-top: -14px;
  margin-bottom: -14px;
  border: 4px solid;
  z-index: 2;
  border-radius: 12px;
  padding: 4px;
}
.currenFoote {
  padding: 0px 16px 16px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-right: 1px solid;
  border-left: 1px solid;
  border-bottom: 1px solid;
}
.setContent {
  min-width: 280px;
  padding: 20px 10px;
  border-radius: 16px;
  border: 1px solid #AEAEAE;
}
.setContent .slipItem {
  line-height: 30px;
}
.setContent .slipItem p {
  text-align: center;
  margin-bottom: 0 !important;
}
.setContent .slipItem p span {
  color: #e89d39;
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid;
}
.setContent .slipItem p .selectSlip {
  background-color: #f3b71e;
  color: #fff;
}
.setContent .slipItemInput {
  display: flex;
  padding: 10px 0px;
}
.setContent .slipItemInput input {
  width: 100%;
  border-radius: 30px;
  line-height: 30px;
  overflow: scroll;
  height: 30px;
  border: 1px solid #f3b71e;
  outline: none;
  text-align: right;
  padding-right: 25px;
  margin-left: 10px;
}
.setContent .slipItemInput p {
  position: relative;
  line-height: 30px;
  left: -20px;
  z-index: 2;
}
.setContent .slipItemTime {
  display: flex;
  padding: 10px 0px;
}
.setContent .slipItemTime input {
  width: 100%;
  border-radius: 30px;
  line-height: 30px;
  overflow: scroll;
  height: 30px;
  border: 1px solid #f3b71e;
  outline: none;
  text-align: right;
  padding-right: 15px;
  margin-left: 10px;
}
.setContent .slipItemTime p {
  line-height: 30px;
  width: 40px;
  text-align: right;
}
