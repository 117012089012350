.poolRemove {
  border: 1px solid;
  border-radius: 16px;
  padding: 16px;
}
.poolRemove .poolRemoveSlider {
  padding: 20px 0px 5px ;
}
.poolRemove .poolRemoveSlider div span {
  background-color: #f3b71e37;
  border: 1px solid #f3b71e37;
  color: #f3b71e;
  border-radius: 8px;
  cursor: pointer;
  padding: 5px 10px;
}
.poolRemove .poolRemoveSlider div span:hover {
  border-color: #f3b71e;
}
.poolRemoveBox {
  padding: 16px;
  border-radius: 16px;
  font-size: 20px;
}
