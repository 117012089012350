.recentTransactionsBox {
  border-radius: 10px;
  padding: 10px 0px;
}
.recentTransactionsBoxConetent {
  width: 100%;
  overflow: scroll;
}
.recentTransactionsBoxConetent .datas {
  display: flex;
  width: 1000px;
  border-bottom: 1px solid #f3b71e;
  padding: 5px 0px;
}
.recentTransactionsBoxConetent .datas .datasItem {
  float: left;
  font-size: 18px;
  font-weight: bold;
}
