.poolAddBox {
  padding: 15px 20px;
  border-radius: 10px;
}
.poolAddBox .messageInfo {
  background: #f3b71e37;
  border-radius: 10px;
  padding: 16px;
  margin-top: 20px;
}
.poolexchange {
  border: 1px solid;
  border-radius: 10px;
}
.poolexchange .label {
  padding: 16px;
}
.poolexchange .content {
  padding: 16px;
  border-radius: 10px;
  border: 1px solid;
}
.poolDetailBox {
  border-radius: 10px;
  padding: 16px;
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;
}
