.mybox {
    border-radius: 10px;
    padding: 10px 10px;

    .myboxItem {
        padding: 8px;
        border-bottom: 1px solid;
        color: rgb(86, 90, 105);
        margin-bottom: 8px;

        .myboxItemImg {
            padding-top: 3px;
        }
    }
}
@primary-color: #1DA57A;