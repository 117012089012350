@--bg2: #ced0d9; //#40444f

.app {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;

    .footer {
        display: none;
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 99;
        padding: 10px 0px;
    }

    @media screen and (max-width: 900px) {
        .footer {
            display: block;
            border: 1px solid;
        }
    }

    .mainContent {
        max-width: 480px;
        margin: 110px auto;
        padding: 15px;

    }
}

::-webkit-scrollbar {
    display: none;
}

.main {
    padding: 16px 16px;

    .mainContentBox {
        border-radius: 16px;
    }
}

.poolHomeLink {
    text-decoration: underline;
    color: #f3b71e;
    text-align: center;
    padding-top: 10px;
}


.flex {
    display: flex;
}

.flex-1 {
    flex: 1;
}

.navSelectColor {
    color: #f3b71e;
}

.footerNavItem {
    text-align: center;
}

.mainColor {
    color: #f3b71e;
}

.font16 {
    font-size: 16px;
}

.fontw500 {
    font-weight: 500;
}

.font18 {
    font-size: 18px;
}

.textCenter {
    text-align: center;
}

.ant-popover-inner-content {
    padding: 0px !important;
}

.ant-popover-inner {
    border-radius: 16px !important;
}

.ant-popover-arrow-content {
    display: none !important;
}

.ant-modal-close-x {
    display: none !important;
}

.tipPop {
    .ant-modal-body {
        padding: 10px !important;
        text-align: center;
    }
}



.rotate {
    animation: rotate-ani 2s linear infinite;
}

@keyframes rotate-ani {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.sendBtn {
    margin: 10px 0px;
    background-color: #f3b71e;
    padding: 16px 0px;
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    border-radius: 20px;
    cursor: pointer;
}

.sendBtnNo {
    margin: 10px 0px;
    padding: 16px;
    text-align: center;
    color: rgb(86, 90, 105);
    font-weight: 500;
    font-size: 20px;
    border-radius: 20px;
    cursor: pointer;
}


.sendBtn:hover {}

.searchtoken {
    margin: 10px 0;
    width: 100%;
    border: 1px solid rgb(206, 208, 217);
    padding: 12px 16px;
    border-radius: 20px;
}

.searchtoken:focus {
    outline: 1px solid #f3b71e !important;
}

.tokenDao {
    border-radius: 16px;
    padding: 16px;

    .tokenDaoBtn {
        span {
            border-radius: 16px;
            background-color: #f3b71e;
            padding: 4px 10px;
            color: #fff;
        }
    }
}

.tokenMangerBox {
    border-radius: 16px;
    padding: 16px 10px;
    text-align: center;

    .tokenManger {
        padding: 10px 45px;
        border-radius: 10px;
    }
}

.switch {
    padding: 3px 10px;
    border-radius: 24px;
    margin-top: 5px;

    .switchbtn1 {
        background-color: #2172e5;
        display: block;
        height: 24px;
        width: 24px;
        border-radius: 24px;
        margin-left: 5px;
        cursor: pointer;
    }

    .switchbtn2 {
        background-color: #888d9b;
        display: block;
        height: 24px;
        width: 24px;
        border-radius: 24px;
        margin-right: 5px;
        cursor: pointer;
    }
}

.switchbg {
    background-color: #2172e5;
}

.whiteColor {
    color: #fff;
}


@media screen and (max-width: 470px) {
    .LoadingBox {
        margin: 0px !important;
        max-width: 100vw !important;
        vertical-align: bottom !important;

        .ant-modal-content {
            border-radius: 18px;

            .ant-modal-body {
                padding: 0px !important;

                .LoadingBoxInfo {
                    border-top-left-radius: 16px;
                    border-top-right-radius: 16px;
                    padding: 16px;
                }
            }
        }
    }

    .tokenCyPop {
        margin: 0px !important;
        max-width: 98vw !important;
        vertical-align: bottom !important;
        .ant-modal-content {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }
    
        .ant-modal-body {
            padding: 0px !important;
        }
    }
}

@media screen and (min-width: 470px) {
    .LoadingBox {
        width: 400px !important;

        .ant-modal-content {
            border-radius: 18px;

            .ant-modal-body {
                padding: 0px !important;

                .LoadingBoxInfo {
                    border-radius: 16px;
                    padding: 16px;
                }
            }
        }
    }

    .tokenCyPop {
        margin: 0px !important;
        width: 400px !important;
        .ant-modal-content {
            border-radius: 18px;
        }
    
        .ant-modal-body {
            padding: 0px !important;
        }
    }
}


.tipPopHash {
    margin: 0px !important;
    width: 100vw !important;
    vertical-align: top !important;

    .ant-modal-content {
        float: right;
        max-width: 200px;
        margin-top: 5px;
        border-radius: 12px;

        .ant-modal-body {
            padding: 5px 30px;
            background-color: #f3b71e;
            color: #fff;
            border-radius: 10px;
        }
    }
}

.ant-message{
    z-index: 100 !important;
}
@primary-color: #1DA57A;